import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Compatibility from "../compatibility/compatibility_beam";
import CarouselMiniatures from "../carousel/carousel-with-miniatures";
import { navigate, useStaticQuery, graphql } from "gatsby";
import { Button } from "gatsby-theme-material-ui";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { formatCurrencyString } from "use-shopping-cart";
import { useShoppingCart } from "use-shopping-cart";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import BenefitsSectionMicroREC from "./benefits";
import "../../../static/microrec.css";
import MicrorecLowerpart from "./microrec_lowerpart";

const ProductSectioBeamSplitter = ({
  adapterLStripe,
  adapterHStripe,
  connect,
  ...props
}) => {
  const { addItem, cartDetails } = useShoppingCart();
  const [open, setOpen] = React.useState(false);

  const data = useStaticQuery(graphql`
    query {
      carrouselImagesOptiREC: allFile(
        filter: { relativePath: { regex: "/ecommerce/img-adapter/" } }
        sort: { fields: relativePath }
      ) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `);

  const carrouselImagesOptiREC = data.carrouselImagesOptiREC;

  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleClick = () => {
    handleAddItem();
    handleBackDrop();
    setTimeout(() => {
      link2UpsellPage();
    }, 400);
  };

  const handleAddItem = () => {
    switch (selectedProduct) {
      case "Adapter Leica":
        addItem(adapterLStripe);
        break;
      case "Adapter Haag-Streeit":
        addItem(adapterHStripe);
        break;
    }
  };
  const handleBackDrop = () => {
    setOpen(!open);
  };

  const [choose, setChoose] = useState("");

  const handleChange = (event) => {
    setChoose(event.target.value);
  };
  const link2UpsellPage = () => {
    navigate("/store/adapter/microrec", {});
  };

  return (
    <section
      id="main_MicroREC_section"
      style={{ marginTop: 110, marginBottom: 0 }}
    >
      <Container maxWidth={"lg"}>
        <Grid
          container
          spacing={3}
          justifyContent={"center"}
          marginTop={{ xs: 2 }}
        >
          <Grid item xs={11} sm={5}>
            <div
              style={{
                marginBottom: "1em",
                fontSize: "18px",
              }}
            >
              <a
                href="/store"
                className="links_post"
                style={{ color: "black", fontWeight: "500" }}
              >
                Catalog
              </a>{" "}
              <b>&gt;</b>{" "}
              <a href="" className="links_post">
                Adapter
              </a>
            </div>
            <CarouselMiniatures data={carrouselImagesOptiREC.edges} />
            <BenefitsSectionMicroREC />
          </Grid>
          <Grid
            container
            item
            xs={11}
            sm={6}
            marginTop={{ xs: 0, sm: 0 }}
            marginBottom={{ xs: 2, sm: 0 }}
            spacing={1}
            justifyContent={"space-between"}
            sx={{ alignContent: "flex-start" }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                align={"left"}
                gutterBottom={true}
                sx={{ fontWeight: "bold", marginTop: "1em" }}
              >
                Adapter
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom={true}>
                <b style={{ fontWeight: "400", fontSize: "16px" }}>from</b>{" "}
                <b>
                  {formatCurrencyString({
                    value: adapterLStripe.price,
                    currency: adapterLStripe.currency,
                  })}{" "}
                </b>
              </Typography>
              <b style={{ fontWeight: "400", fontSize: "14px" }}>
                VAT not included*
              </b>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                paragraph={true}
                gutterBottom={true}
                sx={{
                  textAlign: "justify",
                  fontSize: "18px",
                  marginBottom: "10px",
                }}
              >
                The adapter is sometimes needed when your Microscope or Slit
                Lamp has a different entrance. Check in the compatibility chart
                if you need it.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <hr></hr>
              <Typography
                variant="body1"
                gutterBottom={true}
                sx={{
                  fontSize: "18px",
                }}
              >
                Choose your Adapter:
              </Typography>

              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  shrink={choose ? true : false}
                >
                  {choose ? "" : "Adapter type"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={choose}
                  label="Choose "
                  onChange={handleChange}
                  renderValue={(value) => value}
                >
                  <MenuItem
                    value={"Adapter Leica"}
                    style={{ color: "#838383" }}
                    onClick={() => setSelectedProduct("Adapter Leica")}
                  >
                    Adapter Leica
                  </MenuItem>
                  <MenuItem
                    value={"Adapter Haag-Streeit"}
                    style={{ color: "#838383" }}
                    onClick={() => setSelectedProduct("Adapter Haag-Streeit")}
                  >
                    Adapter Haag-Streeit
                  </MenuItem>
                </Select>
              </FormControl>
              <div style={{ paddingTop: "1em" }}>
                <Compatibility />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              justifyContent={"center"}
              sx={{ textAlign: "center" }}
            >
              <Button
                onClick={handleClick}
                color="primary"
                variant="contained"
                endIcon={<AddShoppingCartOutlinedIcon />}
                disabled={!selectedProduct} // Button is disabled if no product type is selected
                sx={{
                  padding: "8px 30px",
                  marginTop: "1em",
                  borderRadius: "10px",
                }}
              >
                Add to Cart
              </Button>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </Grid>
          </Grid>
          <MicrorecLowerpart />
        </Grid>
      </Container>
    </section>
  );
};

export default ProductSectioBeamSplitter;
